<script setup lang="ts">
// ensure auth store and routing is loaded regardless of route
useAuth()
</script>

<template>
  <div class="flex min-h-screen flex-col items-center justify-center bg-secondary">
    <slot></slot>
  </div>
</template>
